import * as React from "react";
import Layout from "../components/layout";
import CtaButton from "../components/ctabutton";

const NotFoundPage = () => (
  <>
    <Layout>
      <main className="mt-40 flex flex-col mb-52 items-center space-y-8 px-5 md:px-15 ">
        <h1 className="text-3xl font-bold text-center">Page Non trouvée </h1>
        <div className={"text-lg pb-[30rem] text-center md:pb-15 space-y-4"}>
          <p> Il semblerait que vous soyez perdus. </p>
          <p className={"text-base"}>
            Mais ne vous en faites pas, nous pouvons vous trouver le meilleur
            prêt au meilleur taux.
          </p>

          <div className="inline-block">
            <CtaButton origin={"/404"} />
          </div>
        </div>
      </main>
    </Layout>
  </>
);

export default NotFoundPage;
